.report {
  > h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    width: 100%;
  }
}
.printReport{
  margin:30px;
  // border:2px rgba(217, 217, 217, 1) solid;
  border-radius:10px;
  .titleOfReport{
    width: 100%;
         padding-top: 30px;
         font-size: 26px;
         font-weight: 700;
         text-decoration: underline;
         text-align: center;
  }
  .titleSection{
    font-size: 20px;
    font-weight: 600;
    padding-left: 20px;
  }
  .subTitle{
    font-size: 16px;
    font-weight: 600;
    padding-left: 30px;
  }
  .infoBlock{
    display: flex;
    flex-direction: row;
    height: 20px;
    width: 100%;
  }
  .infoTitle{
    padding-left: 20px;
    font-size: 13px;
    font-weight: 600;
  }
  .infoText{
    padding-left: 10px;
    font-size: 13px;
  }
  p{
    padding-left: 40px;
    padding-right: 30px;
  }

}
.body-part {
  max-height: 70vh;
  overflow-y: scroll;
  min-height: 50px;
  background-color: rgba(242, 242, 242, 1);
  border: 1px solid rgba(242, 242, 242, 1);
  color: rgba(76, 76, 76, 1);
  text-align: center;

}
.addButton {
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(134, 134, 134, 0.3);
  background-color: rgba(172, 201, 255, 0.2);
  color: rgba(46, 108, 223, 1);
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  > svg {
    margin-right: 8px;
  }
}
.section {
  background-color: white;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(159, 159, 159, 1);
    border-color: rgba(159, 159, 159, 1);
  }
  .section-head {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    background-color: rgba(234, 234, 234, 1);
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    padding-top: 10px;
    > h5 {
      font-weight: 600;
      font-size: 14px;
    }
  }
  .section-q {
    align-items: center;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    input[type="text"]{
        background-color: rgb(242, 242, 242);
      }
  }
  .answer {
    // align-items: center;
    height: 70px;
    background-color: white;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    input[type="text"]{
        background-color: white;
      }
  }
  .section-c {
    background: rgba(50, 156, 0, 0.15);
    text-align: left;
    border: 0.5px solid rgba(242, 242, 242, 1);
    padding-top: 10px;
    height: 46px;;
    > h3 {
      padding-left: 10px;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
    > div > div > h3 {
      padding-left: 10px;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
audio::-webkit-media-controls-panel {
  background-color: rgba(253, 244, 228, 1);
  border-radius: 0px;
}
audio::-webkit-media-controls-play-button {
  background-color: rgba(253, 244, 228, 1);
  border-radius: 50%;
  color: white;
  stop-color: white;
}
audio::-webkit-media-controls-toggle-closed-captions-button {
  background-color: white;
}
.audio-recorder .audio-recorder-mic {
  background-color: red;
}
.summeryselected {
  font-weight: bold;
  border-bottom: 2px solid black;
  border-right: 1px solid #d9d9d9;
  width: 50%;
  text-align: center;
  transition: 0.5s;
  padding-bottom: 7px;
  cursor: pointer;
}
.summeryselectedvois {
  font-weight: bold;
  border-bottom: 2px solid black;
  border-left: 1px solid #d9d9d9;
  width: 50%;
  text-align: center;
  transition: 0.5s;
  padding-bottom: 7px;
  cursor: pointer;
}

.summery {
  width: 50%;
  text-align: center;
  transition: 0.5s;
  padding-bottom: 7px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.show {
  display: block;
}
:where(.css-dev-only-do-not-override-1ez6nz9).ant-input-disabled,
:where(.css-dev-only-do-not-override-1ez6nz9).ant-input[disabled] {
  background-color: rgba(242, 242, 242, 1);
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.5s;
  color: black;
}

$speed: 2.5s;

* {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

html,
body {
  min-height: 100%;
}

body {
  background: radial-gradient(#eee, #444);
}

.loader {
  // position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 175px;
  height: 30px;
  span {
    display: block;
    background: #ccc;
    width: 7px;
    height: 5%;
    border-radius: 7px;
    margin-right: 5px;
    float: left;
    margin-top: 12%;
    &:last-child {
      margin-right: 0px;
    }
    &:nth-child(1) {
      animation: load $speed 1.4s infinite linear;
    }
    &:nth-child(2) {
      animation: load $speed 1.2s infinite linear;
    }
    &:nth-child(3) {
      animation: load $speed 1s infinite linear;
    }
    &:nth-child(4) {
      animation: load $speed 0.8s infinite linear;
    }
    &:nth-child(5) {
      animation: load $speed 0.6s infinite linear;
    }
    &:nth-child(6) {
      animation: load $speed 0.4s infinite linear;
    }
    &:nth-child(7) {
      animation: load $speed 0.2s infinite linear;
    }
    &:nth-child(8) {
      animation: load $speed 0s infinite linear;
    }
    &:nth-child(9) {
      animation: load $speed 0.2s infinite linear;
    }
    &:nth-child(10) {
      animation: load $speed 0.4s infinite linear;
    }
    &:nth-child(11) {
      animation: load $speed 0.6s infinite linear;
    }
    &:nth-child(12) {
      animation: load $speed 0.8s infinite linear;
    }
    &:nth-child(13) {
      animation: load $speed 1s infinite linear;
    }
    &:nth-child(14) {
      animation: load $speed 1.2s infinite linear;
    }
    &:nth-child(15) {
      animation: load $speed 1.4s infinite linear;
    }
  }
}
@keyframes load {
  0% {
    background: #ccc;
    margin-top: 12%;
    height: 10%;
  }
  50% {
    background: #444;
    height: 100%;
    margin-top: 0%;
  }
  100% {
    background: #ccc;
    height: 10%;
    margin-top: 12%;
  }
}
.Upload-box{
  position: fixed;
  z-index: 3;
  bottom: 0;
  right: 25px;
  width: 25%;
  max-width: 300px;
  height: max-content;
  border-radius: 10px 10px 0 0;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
  .header{
    border-radius: 10px 10px 0 0;
    background-color: #ededed;
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    padding-top: 15px !important;
    
  }
.h-line{
  height: 1px;
  width: 100%;
  background-color: #ccc;
}
.transcript_content{
  line-height: 21px;
  font-size: 15px;
}
.transcript_title{
  font-size: 18px;
  font-weight: 600;
}
.Parent{
  height: 300px;
  overflow-y: scroll;
  background-color: #ffff;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.Transcrip-box{
width: 40%;
max-width: 500px;
border-radius: 10px;
height: max-content;

position: absolute;
top: 10%;
right: 3%;
z-index: 5;
background-color: rgba(245, 245, 245, 1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
.header{
  background-color: #ededed;
  width: 100%;
  height: 45px;
  font-size: 18px;
  font-weight: 600;
  padding-top: 15px !important;
  cursor: move;
  z-index: 10;
}
}